import { Component, OnInit } from '@angular/core';
import { ConfirmationDialogService } from '../services/confirmation-dialog.service';
import { modalFormAnimation } from '../utils/modal-form-animation';

@Component({
  selector: 'app-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css'],
  animations: [modalFormAnimation],
  host: { '[@modalFormAnimation]' : '' },
})
export class ConfirmationDialogComponent implements OnInit {

  constructor(
    public _confirmationDialogService: ConfirmationDialogService,
  ) { }

  ngOnInit() {
  }

}
