import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
//import { SigninComponent } from './auth/signin.component';
import { AppStateService } from './services/app-state.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // handle any redirects if a user isn't authenticated
    // if (!this._appStateService.isValidUser) {
    //   // redirect the user
    //   this.router.navigate(['']);
    //   return false;
    // }
    return true;
    //return false;
  }

  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    // redirect and return false
    // if (!this._appStateService.isValidUser) {
    //   this.router.navigate(['']);
    //   return false;
    // }

    return true;
    // return false;
  }

  constructor(
    //public signIn: SigninComponent,
    public router: Router,
    public _appStateService: AppStateService) { }
}
