import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'selectedIntent'
})
export class SelectedIntentPipe implements PipeTransform {

  transform(intents: Array<any>, args?: any): any {

    console.log(intents);
    
    return intents.filter( intent => {
      return intent.isSelected;
    } );

  }

}
