export class ChatHistoryReport {
    chatData: ChatData[] = [];

    constructor() {
        this.chatData.push(new ChatData());
    }
}

export class ChatData {
    chat_id: string;
    message_author: string;
    message_text: string;
    create_timestamp: string;

    
}