

import { map } from "rxjs/operators";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { GetFeedbackService } from "../../services/get-feedback.service";
import { Report, FeedbackData } from "../../models/report-model";
import { Component, OnInit } from "@angular/core";
import { dashboardAnimation } from "../../utils/dashboard-animation";
import { Workbook } from "exceljs";
import { ToastService } from '../../services/toast.service';
import * as fs from "file-saver";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-feddback-report',
  templateUrl: './feddback-report.component.html',
  styleUrls: ['./feddback-report.component.css']
})
export class FeddbackReportComponent implements OnInit {

  showReportFlag = false;
  reportForm: FormGroup;
  loading = false
  toDate: Date;
  fromDate: Date;
  report = new Report();
  data: any;
  startDate:any
  endDate:any
  header = ["user name", "feedback", "date"];
  Exdata: any;

  constructor(private getFeedbackService: GetFeedbackService,
    private formBuilder: FormBuilder,
    private _toastService: ToastService,) {
      this.report = JSON.parse(sessionStorage.getItem("report")) || new Report();

    if (!this.report.feedbacks || this.report.feedbacks.length === 0) {
      this.report.feedbacks = [];
      this.report.feedbacks.push(new FeedbackData());
    }
     }

  ngOnInit() {
    this.reportForm = this.formBuilder.group({
      toDate: ["", Validators.required],
      fromDate: ["", Validators.required],
    });
  }

  get f() {
    return this.reportForm.controls;
  }
  showReport(reportFormData) {
    this.loading =true
    if (this.reportForm.invalid) {
      return;
    }
    this.startDate = reportFormData.toDate
    this.endDate = reportFormData.fromDate
    this.getFeedbackService.getFeedBack(reportFormData).subscribe(
      (data) => {
        this.loading = false
        if(data.result.length>0){
          this.modifyFeedbackData(data.result);
          this.Exdata = data.result;
        }
        else{
          this.showReportFlag = false
          this._toastService.showToast(2, 'Please enter valid dates !!');
        }
        
      },
      (error) => {
        console.log(error);
      }
    );
  }

  modifyFeedbackData(origdata) {
    let arr = [];
    origdata.map((res) => {
      let pairs = [];
      let y = JSON.parse(res.response);
      if (!y.length) {
        let feedback = [
          {
            Q: "invalid",
            A: "invalid",
          },
        ];
        let dateSliced =
          res.created_date.slice(0, 10) + " " + res.created_date.slice(11, 19);
        let QApair = {
          created_by: res.created_by,
          QAPairs: feedback,
          created_date: dateSliced,
        };
        arr.push(QApair);
      } else {
        y.map((ss) => {
          let Q = Object.keys(ss);
          let A = Object.values(ss);
          let feedback = {
            Q: Q[0],
            A: A[0],
          };
          pairs.push(feedback);
        });
        let dateSliced =
          res.created_date.slice(0, 10) + " " + res.created_date.slice(11, 19);
        let QApair = {
          created_by: res.created_by,
          QAPairs: pairs,
          created_date: dateSliced,
        };
        arr.push(QApair);
      }
    });
    this.data = arr;
    this.showReportFlag = true;
  }

 
  generatePdf(action = "open") {
    let fname = "feedback_report_"+this.startDate+"_"+this.endDate
    const documentDefinition = this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).download(fname);
  }
  generateExcel() {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Feedback Data");
    let title = "Feeback report created for duration "+this.startDate+"to "+this.endDate;
    let titleRow = worksheet.addRow([title]);
    

    let headerRow = worksheet.addRow(this.header);
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    this.data.map((res) => {
      let arr = [];
      let qapairs = "";
      let firstQApairFlag = true
      res.QAPairs.map((qap) => {
        if(firstQApairFlag){
          qapairs = qapairs + "Q:  " + qap.Q + "  " + "A:  " + qap.A + "\n\n";
          let timeSliced =
          res.created_date.slice(0, 10) + " " + res.created_date.slice(11, 19);
          arr.push(res.created_by);
          arr.push(qapairs)
          arr.push(timeSliced);
          firstQApairFlag = false
          let row = worksheet.addRow(arr);
        }
        else{
          qapairs = qapairs + "Q:  " + qap.Q + "  " + "A:  " + qap.A + "\n\n";
          let row = worksheet.addRow(["",qapairs,""]);
        }
        
      });
     
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let fname= "feedback_repport_" + this.startDate + "_"+this.endDate+".xlsx"
      fs.saveAs(blob, fname);
    });
  }

  getDocumentDefinition() {
    sessionStorage.setItem("report", JSON.stringify(this.report));
    return {
      content: [
        {
          text: "REPORT",
          bold: true,
          fontSize: 16,
          alignment: "center",
          margin: [0, 0, 0, 0],
        },
        {
          text: "feedback report for duration "+this.startDate+" to"+this.endDate,
          bold: true,
          fontSize: 12,
          alignment: "center",
          margin: [50, 0, 0, 0],
        },

       
        this.getEducationObject(this.report.feedbacks),
      ],
      info: {
        title: "feedback_report",
        author: "hrishi",
        subject: "feedback_report",
        keywords: "feedback_report",
      },
      styles: {
       
        tableHeader: {
          bold: true,
          fontSize: 12,
          color: "white",
          fillColor: "#8c64f9",
          alignment: "center",
        },
        fontForRow:{
          fontSize:12,
        }
      },
    };
  }
  getEducationObject(feedbacks: FeedbackData[]) {
    return {
      table: {
        widths: "auto",
        body: [
          [
            {
              text: "user name",
              style: "tableHeader",
            },
            {
              text: "feedback",
              style: "tableHeader",
            },
            {
              text: "date",
              style: "tableHeader",
            },
          ],
          ...this.data.map((ed) => {
            let qapairs = "";
            let a =
              ed.created_date.slice(0, 10) +
              " " +
              ed.created_date.slice(11, 19);
            ed.QAPairs.map((res) => {
              qapairs =
                qapairs + "Q:  " + res.Q + "\n" + "A:  " + res.A + "\n\n";
            });
            return [{text:ed.created_by,style:"fontForRow"},{text:qapairs,style:"fontForRow"},{text:a,style:"fontForRow"}];
          }),
        ],
      },
    };
  }

}
