import { Injectable, OnInit } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class TenantDetailsService implements OnInit{
  private _tenantId: string;
  private _tenantName: string;
  private _botId: string;
  private _libId: string;
  private _libName: string;
  private _nlpId: string;
  private _nlpVersionId: string;
  private _nlpEndpointUrl: string;
  private _nlpAppName: string;
  private _botName: string;


  // ONLY for DEV
  // SDCB_Platform_Dev  >>  sdcb_shell - Channels
  // Convert to private
  public botSecret: string;
  //public botName: string;


  public getDummyBotSrc():string{
    let botSecret = 'mqVTpA-Hqyg.cwA.8ts.QoDdFF89J-xgqDUecCcaxOdaQw18SXf-Wqg-BIzcD6A';
    let botName = 'sdcb_shell';
    let srcEmbeddedBot: string = `https://webchat.botframework.com/embed/${botName}?s=${botSecret}`;
    return srcEmbeddedBot;
  }


  public getActualBotSrc():string{
    let srcEmbeddedBot: string = `https://webchat.botframework.com/embed/${this.botName}?s=${this.botSecret}`;
    console.log(`Returning actual-bot src: ${srcEmbeddedBot}`);
    
    return srcEmbeddedBot;
  }


  toString(){
    return `_tenantId: ${this._tenantId} | _tenantName: ${this._tenantName} | _botId: ${this._botId} | _libId: ${this._libId} | _libName: ${this._libName} | _nlpId: ${this._nlpId}`;
  }
  
  /* ------------------------------------- */

  get tenantId(){
    if( !this._tenantId ){
      console.log('Setting dummy value for tenantId...');
      this.tenantId = this.getDummyValue('tenantId');
    }
    
    return this._tenantId;
  }

  set tenantId(newTenantId: string){
    this._tenantId = newTenantId;
    console.log('From Service(set): ' + this.toString());
    localStorage.setItem('tenantId', newTenantId);
  }

  /* ------------------------------------- */

  get tenantName(){
    if( !this._tenantName ){
      console.log('Setting dummy value for tenantName...');
      this.tenantName = this.getDummyValue('tenantName');
    }
    
    return this._tenantName;
  }

  set tenantName(newTenantName: string){
    this._tenantName = newTenantName;
    console.log('From Service(set): ' + this.toString());
    localStorage.setItem('tenantName', newTenantName);
  }

  /* ------------------------------------- */

  get botId(){
    if( !this._botId ){
      console.log('Setting dummy value for botId...');
      this.botId = this.getDummyValue('botId');
    }
    
    return this._botId;
  }

  set botId(newBotId: string){
    this._botId = newBotId;
    console.log('From Service(set): ' + this.toString());
    localStorage.setItem('botId', newBotId);
  }
  
  /* ------------------------------------- */

  get libId(){
    if( !this._libId ){
      console.log('Setting dummy value for libId...');
      this.libId = this.getDummyValue('libId');
    }
    
    return this._libId;
  }

  set libId(newLibId: string){
    this._libId = newLibId;
    console.log('From Service(set): ' + this.toString());
    localStorage.setItem('libId', newLibId);
  }

  /* ------------------------------------- */

  get libName(){
    if( !this._libName ){
      console.log('Setting dummy value for libName...');
      this.libName = this.getDummyValue('libName');
    }
    
    return this._libName;
  }

  set libName(newLibName: string){
    this._libName = newLibName;
    console.log('From Service(set): ' + this.toString());
    localStorage.setItem('libName', newLibName);
  }

  /* ------------------------------------- */

  get nlpId(){
    if( !this._nlpId ){
      console.log('Setting dummy value for nlpId...');
      this.nlpId = this.getDummyValue('nlpId');
    }
    
    return this._nlpId;
  }

  set nlpId(newNlpId: string){
    this._nlpId = newNlpId;
    console.log('From Service(set): ' + this.toString());
    localStorage.setItem('nlpId', newNlpId);
  }

  /* ------------------------------------- */

  get nlpVersionId(){
    if( !this._nlpVersionId ){
      console.log('Setting dummy value for nlpVersionId...');
      this.nlpVersionId = this.getDummyValue('nlpVersionId');
    }
    
    return this._nlpVersionId;
  }

  set nlpVersionId(newNlpVersionId: string){
    this._nlpVersionId = newNlpVersionId;
    console.log('From Service(set): ' + this.toString());
    localStorage.setItem('nlpVersionId', newNlpVersionId);
  }

  /* ------------------------------------- */

  get nlpEndpointUrl(){
    if( !this._nlpEndpointUrl ){
      console.log('Setting dummy value for nlpEndpointUrl...');
      this.nlpEndpointUrl = this.getDummyValue('nlpEndpointUrl');
    }
    
    return this._nlpEndpointUrl;
  }

  set nlpEndpointUrl(newNlpEndpointUrl: string){
    this._nlpEndpointUrl = newNlpEndpointUrl;
    console.log('From Service(set): ' + this.toString());
    localStorage.setItem('nlpEndpointUrl', newNlpEndpointUrl);
  }

  /* ------------------------------------- */

  get nlpAppName(){
    if( !this._nlpAppName ){
      console.log('Setting dummy value for nlpAppName...');
      this.nlpAppName = this.getDummyValue('nlpAppName');
    }
    
    return this._nlpAppName;
  }

  set nlpAppName(newNlpAppName: string){
    this._nlpAppName = newNlpAppName;
    console.log('From Service(set): ' + this.toString());
    localStorage.setItem('nlpAppName', newNlpAppName);
  }

  /* ------------------------------------- */

  get botName(){
    if( !this._botName ){
      console.log('Setting dummy value for botName...');
      this.botName = this.getDummyValue('botName');
    }
    
    return this._botName;
  }

  set botName(newBotName: string){
    this._botName = newBotName;
    console.log('From Service(set): ' + this.toString());
    localStorage.setItem('botName', newBotName);
  }

  /* ------------------------------------- */

  // ONLY for Dev
  /*
  populateDummy(){
    this.tenantId = this._dummyData.tenantId;
    this.tenantName = this._dummyData.tenantName;
    this.libId = this._dummyData.libId;
    this.libName = this._dummyData.libName;
    this.nlpId = this._dummyData.nlpId;
    this.nlpVersionId = this._dummyData.nlpVersionId;
  }
  */

  private _dummyData = {
    tenantId : "52",
    tenantName : "Dummy Inc.",
    libId : "43",
    libName : "ZENITH",
    nlpId : "31",
    botId : "33",
    botName: 'dummy bot',
    nlpVersionId: "1",
    nlpAppName: 'NLP dummy app',
    nlpEndpointUrl: 'www.luis.com/west-us./NLP-dummy-app'
  };

  private _shouldPreferLocalStorage = true;

  getAllDummyDetails(){
    if(this._shouldPreferLocalStorage){
      let dummyObj = {};
      for( let key in this._dummyData ){
        dummyObj[key] = this.getDummyValue(key);
      }
      return dummyObj;
    }
    return this._dummyData
  }

  getDummyValue(key){
    if(this._shouldPreferLocalStorage){
      return (localStorage.getItem(key)) ? localStorage.getItem(key) : this._dummyData[key];
    }
    return this._dummyData[key];
  }

  constructor() {
    //this.populateDummy();
  }

  ngOnInit(): void {

  }
}
