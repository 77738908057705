import { Component } from '@angular/core';
import { MonitoringService } from './services/monitoring.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'CSD-Admin-Client-v1';
  constructor() {
  }
}
