import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { OnDestroy } from '@angular/core/src/metadata/lifecycle_hooks';
import swal from 'sweetalert';
import { DataService } from '../services/data.service';
import { ToastService } from '../services/toast.service';
import { AppStateService } from '../services/app-state.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css']
})
export class SigninComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  userName: any;
  password: any;
  private countLoginInfoHit: number;
  private readonly maxLoginInfoHitCount = 5;

  public isValidUser: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private _route: ActivatedRoute,
    private router: Router,
    public dataService: DataService,
    private _appStateService: AppStateService,
    private _toastService: ToastService,
  ) { }

  ngOnInit() {
    // Delete the auth-token in localStorage if present.
    // this._appStateService.logoutUser();

    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });

    this._route.queryParams.subscribe(
      params => {
        const authToken = params['authToken'];
        sessionStorage.setItem('authToken',authToken)
        console.log('[SignIn - ngOnInit] authToken: ', authToken);
        const storageAccessToken = sessionStorage.getItem('authToken');
        if (storageAccessToken == "undefined") {
          console.log("Redirecting to api gateway"+new Date().getTime())
          window.location.assign(environment.apiGatewayBaseUrl+'oauth2/authorization/okta?domain='+environment.domain)
        }

        // If authToken present in the URI then fetch the login details.
        this.countLoginInfoHit = 0;
        // console.log(authToken)
        this._appStateService.setToken(storageAccessToken);
        this.fetchLoginInfo(storageAccessToken);

        // Save the username from params
        // let uName: string = params['name'];
        // const idx = uName.indexOf('(')
        // this.userName = uName.substring(0, (idx > 0 ? idx : uName.length)).trim();
        // console.log('[SignIn - ngOnInit] userName: ', this.userName);
      });
  }

  private fetchLoginInfo(authToken: string) {
    //this.spinnerService.show();
    this.countLoginInfoHit++;

    this.dataService.fetchAuthInfo(authToken).subscribe(
      data => {
        console.log('[fetchAuthInfo - Success]: ', data);
        const authObj = this.createAuthObj(data, authToken);
        this._appStateService.username = authObj.bot_user
       // this._appStateService.setToken(authObj.jwtToken);
        // this.router.navigate(['/admin/home']);
        this.router.navigate(["/admin/chatReports"], {
          queryParams: { data: btoa(JSON.stringify(authObj)) },
        });
      },

      err => {
        console.log('[fetchAuthInfo - Error]: ', err);
        if (this.countLoginInfoHit <= this.maxLoginInfoHitCount) {
          console.log(`[fetchAuthInfo - Error] Retrying fetching details (Count - ${this.countLoginInfoHit})`);
          setTimeout(() => {
            console.log(`[fetchAuthInfo - Timeout] Initiating hit ...`);
            this.fetchLoginInfo(authToken);
          }, 3500);
        } else {
          //this.spinnerService.hide();
          swal({
            text: 'Something went wrong! Please try again.',
            icon: 'error',
            className: 'swal-wide'
          });
        }
      });
  }

  isUserValidated(data,loginForm){
    if(data.AdminPortalID == loginForm.username){
      if(data.AdminPortalPassword == loginForm.password){
        this._toastService.showToast(1, 'Success!!');
        return true;
      }
      else{
        this._toastService.showToast(2, 'Please enter valid password!!');
        return false;
      }
    }
    else{
      this._toastService.showToast(2, 'Please enter valid username!!');
      return false;
    }
  }

  createAuthObj(authResponse: any, csdXAuthToken: string) {
    const data = authResponse["responseDetails"][0];
    const authObj = {
      "bot_user": this.userName,
      "jwtToken": data['jwtToken'],
      "csdXAuthToken": csdXAuthToken,
      "digitalId": data['digitalId'],
      "directLineToken": data['directLineToken'],
      "defaultLang": data['defaultLang'],
      "availableLangs": data['languages']
    };
    console.log('[createAuthObj] authObj: ', JSON.stringify(authObj));
    return authObj;
  }

  get f() { return this.loginForm.controls; }

  onSubmit(loginData) {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    console.log('login data',loginData);

    this.dataService.fetchAuthCredFromKeyvault()
      .subscribe(
        data => {
          //on success
          if(data.AdminPortalID && data.AdminPortalPassword){
            console.log(data);
            if(this.isUserValidated(data,loginData)) {
              this.loading = false;
              this.router.navigateByUrl('/admin/chatReports');
            } else {
              //this._toastService.showToast(2, 'Please enter valid credentials!!');
              this.loading = false;
            }
          }          
        },
        error => {
          // on error
          this._toastService.showToast(2, 'Please enter valid credentials!!');
          this.loading = false;
        }
      );

    // if(loginData.username === 'TCSADMIN@tcs.com' && loginData.password==='Admin@123'){
    //   this.loading = false;
    //   this.router.navigateByUrl('/admin/home');
    // }
    // else{
    //   this._toastService.showToast(2, 'Please enter valid credentials!!');
    //   this.loading = false;
    // }

    // this.dataService.authenticateuser(loginData)
    //   .subscribe(
    //     data => {
    //       //on success
    //       this._toastService.showToast(1, 'Success!!');
    //       console.log(data);
    //       if(1) {
    //         this._appStateService.username = data.message.bot_user.split('@')[0];
    //         this._appStateService.isValidUser = true;
    //         this._appStateService.setToken(data.message.jwtToken);
    //         this.router.navigateByUrl('/admin/home');
    //       } else {
    //         this._toastService.showToast(2, 'Please enter valid credentials!!');
    //         this.loading = false;
    //       }
    //     },
    //     error => {
    //       // on error
    //       this._toastService.showToast(2, 'Please enter valid credentials!!');
    //       this.loading = false;
    //     }
    //   );
  }
  performSSO(){
    this.dataService.initiateSSO().subscribe(
      data => {
        console.log('[performSso] Success: ', data);
        this.router.navigateByUrl('/admin/admin/home');
      },

      err => {
        console.log('[performSso] Error: ', err);
      }
    );
  }

  ngOnDestroy() {
    this.loading = false;
  }

}
