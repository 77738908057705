import { DecoratedText } from './../../models/DecoratedText';
import { Component, OnInit, HostListener } from '@angular/core';
import { SocketImpl } from 'src/app/services/WebSocketConnect/socketImpl';
import * as momenttz from 'moment-timezone';
import * as fs from "file-saver";
import { Workbook } from "exceljs";
import { GetChathistoryService } from 'src/app/services/get-chathistory.service';
import { ToastService } from 'src/app/services/toast.service';
import * as moment from 'moment-timezone';
import { ChartType, ChartOptions, ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import {
  FormBuilder,
  FormGroup,
  FormArray,
  FormControl,
  ValidatorFn,
  Validators
} from '@angular/forms';

import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { ExcelService } from 'src/app/services/exel.service';
import domtoimage from 'dom-to-image';
import jspdf from 'jspdf';


//import { Console } from 'console';

@Component({
  selector: 'app-live-chat-count',
  templateUrl: './live-chat-count.component.html',
  styleUrls: ['./live-chat-count.component.css']
})
export class LiveChatCountComponent implements OnInit {


  public wait_to_accept: any = "Fetching...";
  public Wait_in_queue: any = "Fetching...";
  public Active_session: any = "Fetching...";
  public Vip_user: any = "Fetching...";
  public Active_agent: any = "Fetching...";
  public Busy_Agent: any = "Fetching...";
  public ticket: any = "Fetching...";


 productForm = this.fb.group({});
  constructor(public socketImpl: SocketImpl,
    public getChathistoryService: GetChathistoryService,
    public _toastService: ToastService,
    private fb: FormBuilder,
    private excelService: ExcelService

  ) {}





  q: number = 1;
  r: number = 1;
  form: FormGroup;
  public TimeZone: string[] = ["CET", "UYT"];
  selectedQuantity = "UYT";
  time = "";
  public timeZoneIst: any;
  public timeZoneEst: any;
  public chatCountTotal: any = "Fetching..."
  public chatCountCompleted: any = "Fetching..."
  public chatCountInProgress: any = "Fetching..."
  public chatCountAbandoned: any = "Fetching.."
  public date: any
  public timeZone: any
  public dateForLive: any;
  public chatHistoryResponse: any;
  public timerForLiveReport: any
  public timerForChatCount: any
  public loading: boolean = false;
  public showChatHistoryFlag: boolean = false;
  public p: any = 1;
  public count: Number = 10;
  public data;
  public timeToCompare: any
  index = 0
  header = [
    "Sr. No.",
    "Requester Name",
    "Requester ID",
    "Session Start Time",
    "Session End Time",
    "Average Speed To Answer",
    "Chat Transcript"]

  userDetails: any = [];



public timeZoneCet;
  getTime() {
    setInterval(() => {
      this.timeToCompare = momenttz().tz('America/Chicago').format()
      this.timeToCompare = this.timeToCompare.substring(0, 19)
      let date = new Date()
       let m = momenttz.utc(date);
      // this.date = m.tz('America/Chicago').format('ll')
      this.timeZoneCet = m.tz('Europe/Berlin').format('LTS') + " CET"
      // let momenttime = momenttz()

      // this.timeZoneIst = momenttime.format("hh:mm:ss a") + " IST"

      this.timeZoneEst = moment().tz("America/Argentina/Buenos_Aires").format('LTS') + " UYT"
      if (this.selectedQuantity == "CET") {
        this.time = this.timeZoneCet;
        this.date = moment().tz("America/Chicago").format("ll")

      }
      else {
        this.time = this.timeZoneEst;
        this.date = moment().tz("America/Argentina/Buenos_Aires").format('ll')

      }

    }, 1000);
  }






  getLiveAgentDetailsByTime : any = [];
  getUserAgentStatusByTime  : any = [];

  abandoned_chats="Fetching...";
  Missed="Fetching...";
  reassigned_chats="Fetching...";
  max_waittime="Fetching...";
  chats_handledby_bot="Fetching...";
  Received="Fetching...";
  Handled="Fetching...";
  language="Fetching...";
  AHT="Fetching...";
  average_wait_time="Fetching...";
  ART="Fetching...";
  average_aband_time="Fetching...";
  ngOnInit() {



    this.getTime();
    //console.log('dummy', this.dummy);

    this.getLiveDate(date => {
      this.dateForLive = date.substring(0, 4) + "-" + date.substring(8, 10) + "-" + date.substring(5, 7)
    })
    console.log("------->date", this.dateForLive);



    forkJoin([
      this.socketImpl.getLiveAgentStatusByTime(),
      this.socketImpl.getWaitQueueCount(),
      this.socketImpl.get_All_agent_by_details(),
      this.socketImpl.get_ongoing_chat_count(),
      this.socketImpl.getReportBasisAverage(this.dateForLive),
    ]).subscribe(data=>{
      console.log('data',data);
      this.Active_agent=data[0].Available_Agents_count;
      this.Busy_Agent=data[0].Busy_Agents_count;
      this.Wait_in_queue=data[1].English_chats;
      this.getLiveAgentDetailsByTime=data[2];
      this.Active_session=data[3].ActiveChat;
      this.abandoned_chats=data[4][0].abandoned_chats;
      this.Missed=data[4][0].Missed;
      this.reassigned_chats=data[4][0].reassigned_chats;
      this.max_waittime=data[4][0].max_waittime;
      this.chats_handledby_bot=data[4][0].chats_handledby_bot;
      this.Received=data[4][0].Received;
      this.Handled=data[4][0].Handled;
      this.language=data[4][0].language;
      this.AHT=data[4][0].AHT;
      this.average_wait_time=data[4][0].average_wait_time;
      this.ART=data[4][0].ART;
      this.average_aband_time=data[4][0].average_aband_time;
    })



   this.timerForChatCount = setInterval(() => {
    forkJoin([
      this.socketImpl.getLiveAgentStatusByTime(),
      this.socketImpl.getWaitQueueCount(),
      this.socketImpl.get_All_agent_by_details(),
      this.socketImpl.get_ongoing_chat_count(),
      this.socketImpl.getReportBasisAverage(this.dateForLive),
    ]).subscribe(data=>{
      console.log('data',data);
      this.Active_agent=data[0].Available_Agents_count;
      this.Busy_Agent=data[0].Busy_Agents_count;
      this.Wait_in_queue=data[1].English_chats;
      this.getLiveAgentDetailsByTime=data[2];
      this.Active_session=data[3].ActiveChat;
      this.abandoned_chats=data[4][0].abandoned_chats;
      this.Missed=data[4][0].Missed;
      this.reassigned_chats=data[4][0].reassigned_chats;
      this.max_waittime=data[4][0].max_waittime;
      this.chats_handledby_bot=data[4][0].chats_handledby_bot;
      this.Received=data[4][0].Received;
      this.Handled=data[4][0].Handled;
      this.language=data[4][0].language;
      this.AHT=data[4][0].AHT;
      this.average_wait_time=data[4][0].average_wait_time;
      this.ART=data[4][0].ART;
      this.average_aband_time=data[4][0].average_aband_time;
    })
   }, 5000);
  }



  getLiveDate(callback) {
    this.timeToCompare = momenttz().tz('America/Chicago').format()
    this.timeToCompare = this.timeToCompare.substring(0, 19)
    let date = new Date();
    // let m = momenttz.utc(date);
    // let dateFF= m.tz("America/Chicago").format("L");
    let momenttime = momenttz()
    let dateFF = moment().tz("America/New_York").format("L");


    this.dateForLive =
      dateFF.substring(6, 11) +
      "-" +
      dateFF.substring(3, 5) +
      "-" +
      dateFF.substring(0, 2);
    callback(this.dateForLive)
  }

  onRefresh() {


    this.getLiveDate(date => {
      this.dateForLive = date.substring(0, 4) + "-" + date.substring(8, 10) + "-" + date.substring(5, 7);
      this.socketImpl.getLiveAgentDetailsByTime(this.dateForLive).subscribe(data=>{
        console.log('getLiveAgentDetailsByTime',data);
        this.getLiveAgentDetailsByTime=data;
      });


      forkJoin([
        this.socketImpl.getLiveAgentStatusByTime(),
        this.socketImpl.getWaitQueueCount(),
        this.socketImpl.get_All_agent_by_details(),
        this.socketImpl.get_ongoing_chat_count(),
        this.socketImpl.getReportBasisAverage(this.dateForLive),
      ]).subscribe(data=>{
        console.log('data',data);
        this.Active_agent=data[0].Available_Agents_count;
        this.Busy_Agent=data[0].Busy_Agents_count;
        this.Wait_in_queue=data[1].English_chats;
        this.getLiveAgentDetailsByTime=data[2];
        this.Active_session=data[3].ActiveChat;
        this.abandoned_chats=data[4][0].abandoned_chats;
        this.Missed=data[4][0].Missed;
        this.reassigned_chats=data[4][0].reassigned_chats;
        this.max_waittime=data[4][0].max_waittime;
        this.chats_handledby_bot=data[4][0].chats_handledby_bot;
        this.Received=data[4][0].Received;
        this.Handled=data[4][0].Handled;
        this.language=data[4][0].language;
        this.AHT=data[4][0].AHT;
        this.average_wait_time=data[4][0].average_wait_time;
        this.ART=data[4][0].ART;
        this.average_aband_time=data[4][0].average_aband_time;
        this._toastService.showToast(1,"Refreshed");
      },err=>{
        console.log("err",err);
        this._toastService.showToast(2,"Failed To Refresh");
      })

    })





  }




  onopenclick(transcript: any) {
    var modal = document.getElementById("myModal");
    this.data = transcript;
    modal.style.display = "block";
  }
  oncloseclick() {
    var modal = document.getElementById("myModal");
    modal.style.display = "none";
  }

  generateExcell() {
    window.print();

  }

  generateExcel() {
    this.index = 0
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Live Report");
    let headerRow = worksheet.addRow(this.header);
    worksheet.autoFilter = {
      from: 'A1',
      to: 'F1',
    }
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });

    this.chatHistoryResponse.map((res) => {
      let qaPair: any = ""
      res.map((ele) => {
        if (ele.user_utterance != "") {
          qaPair = qaPair + "\r\n USER:" + ele.user_utterance
        }
        else if (ele.bot_response != "") {
          let bot_response = new DecoratedText(ele.bot_response);
          let text = "";
          bot_response.decoratedTextList.map((val) => {
            text = text + val.text;
          });
          qaPair = qaPair + "\r\n BOT:" + text
        }
      })
      this.index = this.index + 1
      let row = worksheet.addRow([this.index, res[0].user_name, res[0].user_id, res[0].session_start_timestamp.substring(0, 10) + " " + res[0].session_start_timestamp.substring(11, 19), res[0].session_end_timestamp.substring(0, 10) + " " + res[0].session_end_timestamp.substring(11, 19), res[0].AverageGreetingTime, qaPair]);
    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let fname = "chat_transcript_report_" + ".xlsx"
      fs.saveAs(blob, fname);
    });
  }

  ngOnDestroy() {
    clearInterval(this.timerForChatCount);
    clearInterval(this.timerForLiveReport);
  }

}
