import { trigger, transition, animate, style, query, group } from "@angular/animations";

export const toastAnimation = trigger( 'toastAnimation', [

    transition( ':enter', [
        style({
            transform: 'translateY(100px) scale(0.5)',
            opacity: '1',
        }),
        animate( '2s ease-out' )
    ] ),

    transition( ':leave', [
        animate( '2s ease-out', style({
            opacity: '1',
        }) ),
    ]),
] );

// export const toastAnimation = trigger( 'toastAnimation', [

//     transition( ':enter', group([
//         query( '.toast', [
//             style({
//                 transform: 'translateY(100px) scale(0.5)',
//                 opacity: '0.0',
//             }),
//             animate( '0.3s ease-out' )
//         ] ),
//     ]) ),

//     transition( ':leave', group([
//         query( '.toast', [
//             animate( '0.3s ease-out', style({
//                 opacity: '0.0',
//             }) ),
//         ] ),
//     ]) ),
// ] );