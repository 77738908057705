import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-usecase',
  templateUrl: './usecase.component.html',
  styleUrls: ['./usecase.component.css']
})
export class UsecaseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
