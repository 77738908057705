import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AppStateService } from './app-state.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationDialogService {
  public shouldShowConfirmDlg = false;
  public confirmDlgType: number;
  public okAction;

  public msgTitle: string;
  public msgBody: string;

  showConfirmDlg(msgTitle: string, msgBody: string, dialogType: number){
    this.msgTitle = msgTitle;
    this.msgBody = msgBody;
    this.confirmDlgType = dialogType;

    this.shouldShowConfirmDlg = true;
  }

  hideConfirmDlg(){
    this.shouldShowConfirmDlg = false;
  }

  // Exit from onboard-tenant process
  confirmExitFromOnboarding(){
    this.msgTitle = "Are you sure?";
    this.shouldShowConfirmDlg = true;
    this.confirmDlgType = 1;

    if(this._appStateService.isEditModeActive){
      this.msgBody = "Do you want to exit without updating?";
      this.okAction = () => {
        this.redirectAndClose('/admin/edit-config/view/bot');
      };
    }
    else{
      this.msgBody = "Do you want to exit from the tenant on-bording process?";
      this.okAction = () => {
        this.redirectAndClose('/admin/home');
      };
    }
       
  }

  redirectAndClose(url: string){
    this.router.navigateByUrl(url);
    this.shouldShowConfirmDlg = false;
  }

  constructor(
    private router: Router,
    private _appStateService: AppStateService,
  ) {
  /*  this.okAction = () => {
      this.hideConfirmDlg();
      this.router.navigateByUrl('/admin/home');
    };*/
  }
}
