import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class ExcelService {

  constructor() { }

  public exportAsExcelFile(json: any[], excelFileName: string): void {
    
    const myworksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const myworkbook: XLSX.WorkBook = { Sheets: { 'data': myworksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }

  // public exportAsExcelFile2(data1: any[],data2:any[],data3:any[],data4:any[],data5:any[],data6:any, excelFileName: string): void {
  //   const myworksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data1);
  //   const myworksheet2: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data2);
  //   const myworksheet3: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data3);
  //   const myworksheet4: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data4);
  //   const myworksheet5: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data5);
  //   const myworksheet6: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data6);

  //   const myworkbook: XLSX.WorkBook = { Sheets: { 'data1': myworksheet1,'data2':myworksheet2,'data3':myworksheet3,'data4':myworksheet4,'data5':myworksheet5,'data6':myworksheet6 }, SheetNames: ['data1','data2','data3','data4','data5','data6'] };
  //   const excelBuffer: any = XLSX.write(myworkbook, { bookType: 'xlsx', type: 'array' });
  //   this.saveAsExcelFile(excelBuffer, excelFileName);
  // }

  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_exported'+ EXCEL_EXTENSION);
  }

}