import { BrowserModule } from '@angular/platform-browser';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';

import { ReactiveFormsModule } from '@angular/forms'; // Vidhi
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExcelService } from "./services/exel.service"


import { AppRoutingModule, routingComponents } from './app-routing.module';


/* Common Components */
import { AppComponent } from './app.component';
import { NavbarComponent } from './navbar/navbar.component';

/* Modal Components */
import { Pnf404Component } from './pnf404/pnf404.component';

/* PIPES etc*/
import { FilterPipe } from './filter-pipe';
import { SelectedIntentPipe } from './selected-intent.pipe';

/* Graphs */
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ChartsModule } from 'ng2-charts';

/* Pages */
import { SigninComponent } from './auth/signin.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { Sidebar2Component } from './sidebar2/sidebar2.component';
import { CsdSelectValidatorDirective } from './utils/csd-select-validator.directive';
import { ToastComponent } from './toast/toast.component';
import { DashboardHome2Component } from './dashboard-home2/dashboard-home2.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';

import { AuthInterceptorService } from './services/auth-interceptor.service';
import { ResponseInterceptorService } from './services/response-interceptor.service';
import { FeddbackReportComponent } from './dashboard-home2/feddback-report/feddback-report.component';
import { ChathistoryReportComponent } from './dashboard-home2/chathistory-report/chathistory-report.component';
import { LiveChatCountComponent } from './dashboard-home2/live-chat-count/live-chat-count.component';
import { ChatModalComponent } from './chat-modal/chat-modal.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppMaintenanceComponent } from './app-maintenance/app-maintenance.component';
import { NgDraggableModule } from 'angular-draggable';
import { CommonModule } from '@angular/common';
import { MatTabsModule } from '@angular/material';
import { UserComponent } from './user/user.component';
import { UsecaseComponent } from './usecase/usecase.component';
import { LogOutComponent } from './log-out/log-out.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    routingComponents,

    FilterPipe,
    SelectedIntentPipe,
    Pnf404Component,

    SigninComponent,
    DashboardComponent,
    Sidebar2Component,
    CsdSelectValidatorDirective,
    ToastComponent,
    DashboardHome2Component,
    ConfirmationDialogComponent,
    FeddbackReportComponent,
    ChathistoryReportComponent,
    LiveChatCountComponent,
    ChatModalComponent,
    AppMaintenanceComponent,
    UserComponent,
    UsecaseComponent,
    LogOutComponent,

  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    NgxChartsModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    ChartsModule,
    NgDraggableModule,
    CommonModule,
    MatTabsModule,
    MatProgressSpinnerModule
  ],

  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ResponseInterceptorService,
      multi: true
    },
    ExcelService
  ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ],

  bootstrap: [AppComponent]
})

export class AppModule { }
