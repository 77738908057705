export class Report {
    feedbacks: FeedbackData[] = [];

    constructor() {
        this.feedbacks.push(new FeedbackData());
    }
}

export class FeedbackData {
    response: string;
    created_by: string;
    created_date: string;
}
