import { DecoratedText } from './../models/DecoratedText';
import { Component, OnInit,Input, SimpleChanges, OnChanges } from '@angular/core';

@Component({
  selector: 'app-chat-modal',
  templateUrl: './chat-modal.component.html',
  styleUrls: ['./chat-modal.component.css']
})
export class ChatModalComponent implements OnInit, OnChanges {

  @Input() dataFromChatHistory: any;
  public newChatData = [];
  public data: any;

  constructor() { }

  ngOnInit() {
  }

  ngOnChanges(change: SimpleChanges) {
    this.newChatData = [];
    console.log("change", change);
    this.data = change.dataFromChatHistory.currentValue;
    this.data.map((value) => {
      let obj = {
        bot_response: "",
        user_utterance: "",
      };
      let bot_response = new DecoratedText(value.bot_response);
      let text = "";
      bot_response.decoratedTextList.map((val) => {
        text = text + val.text;
      });
      obj.bot_response = text;
      obj.user_utterance = value.user_utterance;
      this.newChatData.push(obj);
    });
  }
}
