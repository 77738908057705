import { Component, OnInit } from '@angular/core';
import { ProgressbarService } from '../services/progressbar.service';
import { AppStateService } from '../services/app-state.service';
import { SocketImpl } from '../services/WebSocketConnect/socketImpl';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  username:string ='';
  testVar:string='test';
  constructor(
    public _pbarService: ProgressbarService,
    public _appStateService: AppStateService,
    public socketImpl : SocketImpl
  ) { }

  ngOnInit() {
    this.username='Admin';
    console.log(this.testVar);
  }

  onLogoutClicked(){
    // let url = "https://csd-uat.cargill.com/ui/login"
    // window.location.replace(url);
    this._appStateService.logoutUser();
  }
  

}
