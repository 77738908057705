import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStateService } from '../services/app-state.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GetFeedbackService {

  constructor( private _appStateService: AppStateService,  private _http: HttpClient) { }
  urlFeedbackByDate ='/fb/getReportByTime/startDate/:createdDate'
  endDateurl = '/endDate/:createEndDate'
  adminServerUrl = environment.adminServerUrl + "/api/v1/admin"
  getFeedBack(reportFormData){
    let toDate = reportFormData.toDate
    let fromDate = reportFormData.fromDate
    this._appStateService.setToken("eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6InBsYXRmb3JtX3NlcnZlciIsImlhdCI6MTU4Njg5Mzc3Nn0.sbUqxXv3Kqgfjv5XE90oYsGak4iWTyWoyXgFpzoAQ9A");
    let url = this.adminServerUrl + this.urlFeedbackByDate.replace(':createdDate', fromDate)+this.endDateurl.replace(':createEndDate', toDate);
    return this._http.get<any>( url );
  }
}
