import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar2',
  templateUrl: './sidebar2.component.html',
  styleUrls: ['./sidebar2.component.css']
})
export class Sidebar2Component implements OnInit {

  public currComponent;

  constructor(
    private router : Router,
  ) {

    router.events.subscribe( () => {
      let tempPathList = this.router.url.split('/');
      let lastVal1 = tempPathList[ tempPathList.length - 1 ];
      let lastVal2 = tempPathList[ tempPathList.length - 2 ];
      
      if(lastVal2 == "onboard-tenant" || lastVal2 == "edit-config"){
        this.currComponent = lastVal2;
        return;
      }

      if( this.currComponent != lastVal1 ){
      this.currComponent = lastVal1;
      console.log(this.currComponent);
      }
    } );

  }

  ngOnInit() {
  }

}
