import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-pnf404',
  templateUrl: './pnf404.component.html',
  styleUrls: ['./pnf404.component.css']
})
export class Pnf404Component implements OnInit {
  public unauthUrl: string;
  public shouldShow401 = false;
  public pageHeading = 'PAGE NOT FOUND';
  public pageBody = 'The page you are looking for was either moved/renamed/removed or never existed!';
  public redirectText = 'Home';
  public redirectLink = '/admin/home';


  private configure401() {
    this.shouldShow401 = true;
    this.pageHeading = 'UNAUTHORIZED ACCESS';
    // if (this.unauthUrl && this.unauthUrl !== 'null') {
    //   this.pageBody = `You do not have the required privileges to access the page at '${this.unauthUrl}'!`;
    // } else {
    //   this.pageBody = `You do not have the required privileges to access the page!`;
    // }
    this.pageBody = `You do not have the required privileges to access the page!`;
    this.redirectText = 'Login as another user';
    this.redirectLink = '/login';
  }


  constructor(
    // private _route: ActivatedRoute,
    private _router: Router,
  ) { }

  ngOnInit() {
    // this.unauthUrl = decodeURI(this._route.snapshot.paramMap.get('unauthUrl'));
    // console.log(` ** Query params: ${this.unauthUrl}`);
    if (this._router.url.split('/').reverse()[0] == 'unauthorized-access') {
      this.configure401();
    }
  }

}
