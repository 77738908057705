import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AppStateService } from '../services/app-state.service';
@Injectable({
  providedIn: 'root'
})
export class GetChathistoryService {

  constructor(private _appStateService: AppStateService,  private _http: HttpClient) { }

  // baseUrl=environment.apiGatewayBaseUrl + '/api/v2.0/"
  baseUrl="http://localhost:8080/api/v2.0/"
  reportUrl="api/v2.0/admin/report/"

  public getUsersList(data:any,flag:boolean){
    let payload={...data,count:flag}
    let Url=environment.platformServerUrl + this.reportUrl + 'userslist';
    return this._http.post<any>(Url,payload);
  }

  public getUsersListWithDate(data:any,channelValue:string,flag:boolean){
    let payload ={...data,channel:channelValue,chart:flag}
      let Url=environment.platformServerUrl + this.reportUrl + 'userslistwithdate';
      return this._http.post<any>(Url,payload);
  }

  public getCountryWiseCount(data:any,channelValue:string,flag:boolean){
    let payload ={...data,channel:channelValue,chart:flag}
      let Url=environment.platformServerUrl + this.reportUrl + 'countrywisecount';
      return this._http.post<any>(Url,payload);
  }

  public getLanguageWiseCount(data:any,flag:boolean){
    let payload ={...data,chart:flag}
    let Url=environment.platformServerUrl + this.reportUrl + 'languagewisecount';
    return this._http.post<any>(Url,payload);
  }

  public getUseCaseWiseCount(data:any,channelValue:string,reportValue:string){
    let payload ={...data,channel:channelValue,report:reportValue}
      let Url=environment.platformServerUrl + this.reportUrl + 'usecasewisecount';
      return this._http.post<any>(Url,payload);
  }

  public getUseCaseWiseFeedback(data:any,reportValue:string){
    let payload ={...data,report:reportValue}
    let Url=environment.platformServerUrl + this.reportUrl + 'usecasewisefeedback';
    return this._http.post<any>(Url,payload);
  }

  public getLiveAgentChatFeedback(data:any,usecaseValue:string,channelValue:string,reportValue:string){
    let payload ={...data,usecase:usecaseValue,channel:channelValue,report:reportValue}
    let Url=environment.platformServerUrl + this.reportUrl + 'liveagentchatfeedback';
      return this._http.post<any>(Url,payload);
  }
  // public getknowledgeArticleReports(data:any,reportValue:string){
  //   let payload={...data,report:reportValue}
  //   let Url=environment.platformServerUrl + this.reportUrl + 'knowledgearticlerpt';
  //     return this._http.post<any>(Url,payload);
  // }
}
