import { AppStateService } from "./../services/app-state.service";
import { Component, OnInit } from "@angular/core";
import { dashboardAnimation } from "../utils/dashboard-animation";
import { SocketImpl } from './../services/WebSocketConnect/socketImpl';
@Component({
  selector: "app-dashboard-home2",
  templateUrl: "./dashboard-home2.component.html",
  styleUrls: ["./dashboard-home2.component.css"],
  animations: [dashboardAnimation],
  host: { "[@dashboardAnimation]": "" },
})
export class DashboardHome2Component implements OnInit {

  constructor(public socketImpl:SocketImpl,
    public _appStateService: AppStateService) {
  }

  ngOnInit() {
    // let params = window.location.search.split('?')[1].split('&');
    // let properUname = params[0].split('=')[1]
    // this._appStateService.username = atob(properUname.split("%3D")[0])
    // this._appStateService.isValidUser = true;
    // let properToken = params[1].split('=')[1]
    // let token = atob(properToken.split('%3D')[0])
    // this._appStateService.setToken(token);
  }

}
