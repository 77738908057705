import { trigger, transition, animate, style, query, group, stagger } from "@angular/animations";

export const dashboardAnimation = trigger( 'dashboardAnimation', [
    transition( ':enter', group([
        query( '.anim-item, .tile', [
            style({
                transform: 'translateY(20px)',
                opacity: '0.0',
            }),
            stagger( '0.4s', animate( '0.5s ease-out' ) )
        ], { optional: true } ),
    ]) ),
] );

//stagger( '0.2s', animate( '0.3s ease-out' ) )
