import { Directive } from '@angular/core';
import { NG_VALIDATORS, Validator, FormControl } from '@angular/forms';

@Directive({
  selector: '[appCsdInputValidator]',
  providers: [{
    provide:NG_VALIDATORS,
    useExisting: CsdSelectValidatorDirective,
    multi: true
  }]
})
export class CsdSelectValidatorDirective implements Validator{

  validate(control: FormControl): {[key:string]: any} | null{
    let val = control.value;    
    if( (val === '' || val === '-1') && control.touched){
      return {
        'defaultSelected': true,
        'errMsg': 'Selecting an option is mandatory.'
      }
    }else{
      return null;
    }
  }

  constructor() { }

}
