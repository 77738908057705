import { Component, OnInit } from '@angular/core';
import { TenantDetailsService } from '../services/tenant-details.service';
import { ToastService } from '../services/toast.service';
import { ConfirmationDialogService } from '../services/confirmation-dialog.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {

  menu: boolean = true;

  constructor(
    private _tenantDetailsService: TenantDetailsService,
    private _toastService: ToastService,
    public _confirmationDialogService: ConfirmationDialogService,
  ) { }

  

  ngOnInit() {
    //this._toastService.showToast();
    //this._confirmationDialogService.showConfirmDlg("Oops", "Something went wrong!", 1);
  }

}
