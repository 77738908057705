import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';

import { TenantModel } from '../models/tenant-model';
import { TenantBotModel } from '../models/tenant-bot-model';
import { IntentModel } from '../models/intent-model';
import { ToolModel } from '../models/tool-model';
import { NlpAppModel } from '../models/nlpapp_model';
import { TenantDetailsService } from './tenant-details.service';
import { environment } from 'src/environments/environment';


/*
import { Http, RequestOptions, Headers, RequestMethod } from '@angular/http';
*/
@Injectable({
  providedIn: 'root'
})
export class DataService {

  private _isDummyRoutingActivated = false; // *_* //
  // ---------------------------------------------------------------------------------

  private _adminServerUrl = environment.adminServerUrl + '/api/v1/admin/';
  private _platformServerUrl = environment.platformServerUrl + '/api/messages/';

  // ---------------------------------------------------------------------------------
  //  POST
  private _urlCreateTenant = 'bot/tenant';
  private _urlCreateTenantBot = 'tenantBot/tenantbot/registration';
  private _urlCreateChannel = 'tenantChannel/tenantBotChnl';
  private _urlCreateIntent = 'nlp/intents';
  private _urlSaveIntents = 'nlp/intents/save';
  private _urlSaveUtterance = 'nlp/utterance';
  private _urlSaveAllUtterancesWithoutEntity = 'nlp/utterances/save';
  private _urlSaveExternalService = 'tool/tool';
  private _urlCreateNLPApp = 'nlp/app';
  private _urlAssociateIntentDialog = 'intent/associate';
  private _urlAssociateTenantBotLibrary = 'tenantBot/library/associate';
  private _urlCreateLibrary = 'library/lib';
  private _urlPublishBot: string = 'tenantBot/publish';
  private _urlTrainNlpApp: string = 'nlp/nlpapp/train/_appId_/versions/_versionId_';
  private _urlPublishNlpApp: string = 'nlp/nlpapp/publish/_appId_/versions/_versionId_';
  private _urlSaveNewDialogs: string = 'dialog';
  private _urlSaveOrgDetails: string = 'organisation/org';

  //  GET
  private _urlGetAllPlatformIntents = 'nlp/intents/common';
  private _urlGetAllIntentsForNlp = 'intent/nlp/';
  private _urlGetAllIntentsForLibrary = 'nlp/intents/library/_libId_';
  private _urlGetIntentsUtterancesForNlp = 'nlp/utterances/intents/nlp/';
  private _urlGetAllDialog = 'dialog/all/';
  private _urlAzureAadAuth = 'security/auth/aad';
  private _urlGetAllTenants = 'bot/tntAll';
  private _urlGetAllBotsForTenant = "tenantBot/_tenantId_";

  private _urlGetBotServiceDetails = 'tenantBot/channels/_tenantBotId_';
  private _urlGetPlatformChannels = 'tenantChannel/channelTypes';
  private _urlUpdateBotChannelSecret = 'tenantChannel/tenantBotChnl/_pKey_';

  //---------------------------------------------------------------------------------
  //Edit/Update
  //----------------------------------------------------------------------------------

  private _urlUpdateTenant = '/_tenantId_';
  private _urlUpdateNlp = '';
  private _urlUpdateExt = '';
  
//----------------------------------------------------------------------------------
  private readonly urlLoginDetails = 'login/details';
  private readonly apiV2 = 'api/v2.0/';

  get adminServerUrl() {
    return this._adminServerUrl;
  }

  get platformServerUrl() {
    return this._platformServerUrl;
  }

  getHeader(): HttpHeaders{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json;  charset=UTF-8',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    headers.set('Access-Control-Allow-Origin', '*');
    return headers;
  }
  private header = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };

  createNewTenant( newTenant: TenantModel ) {
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlCreateTenant;
    return this._http.post<any>( url, newTenant );
  }


  createNewBotReg( newBotReg: TenantBotModel ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlCreateTenantBot;
    return this._http.post<any>( url, newBotReg );
  }

  
  createNewIntent( newIntent: IntentModel ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlCreateIntent;
    return this._http.post<any>( url, newIntent );
  }


  saveChannelSecrets( channelsList: any ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlCreateChannel;
    return this._http.post<any>( url, channelsList );
  }

  updateChannelSecrets( pKey, data ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlUpdateBotChannelSecret.replace('_pKey_', pKey);
    return this._http.put<any>( url, data );
  }

  
  /*
  getAllPlatformIntents(){
    const headers = new Headers({
      'Content-Type': 'application/json;  charset=UTF-8',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    let url = this.dbUrl + this._urlGetAllPlatformIntents;

    const options = new RequestOptions({ method: RequestMethod.Get, headers: headers });

    return this.http2.get(url, options);
  }
  */

  saveSelectedIntents( payload: any ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlSaveIntents;
    return this._http.post<any>( url, payload );
  }

  
  getAllPlatformIntents(){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlGetAllPlatformIntents;
    // return this._http.get<any>(url, { headers: this.getHeader() } );
    return this._http.get<any>(url);
  }


  createNlpApp( newAppCreateObj: NlpAppModel ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlCreateNLPApp;
    return this._http.post<any>( url, newAppCreateObj );
  }


  getIntentsforNlpApp( id: number ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlGetAllIntentsForNlp + id;
    return this._http.get<any>(url);
  }


  getAllDialogs(){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlGetAllDialog;
    return this._http.get<any>(url);
  }
  

  saveUtterance( payload: any ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlSaveUtterance;
    return this._http.post<any>( url, payload );
  }


  saveAllUtterances( payload: any ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlSaveAllUtterancesWithoutEntity;
    return this._http.post<any>( url, payload );
  }


  getIntentsUtterancesForNlp( nlpId: string ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlGetIntentsUtterancesForNlp + nlpId;
    return this._http.get<any>( url );
  }


  createNewTool( newTool: ToolModel ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlSaveExternalService;
    return this._http.post<any>( url, newTool );
  }


  associateIntentDialog( data: any ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlAssociateIntentDialog;
    return this._http.post<any>( url, data );
  }

  associateTenantBotLibrary( data: any ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlAssociateTenantBotLibrary;
    return this._http.post<any>( url, data );
  }


  createLibrary( data: any ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlCreateLibrary;
    return this._http.post<any>( url, data );
  }


  trainNlpApp( appId: string, versionId: string ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlTrainNlpApp
      .replace('_appId_', appId)
      .replace('_versionId_', versionId);

    console.log(`Train LUIS url: ${url}`);
    
    return this._http.post<any>( url, null );
  }


  publishNlpApp( appId: string, versionId: string ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlPublishNlpApp
      .replace('_appId_', appId)
      .replace('_versionId_', versionId);

    console.log(`Publish LUIS url: ${url}`);
    
    return this._http.post<any>( url, null );
  }


  publishBotToPlatform( data: any ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlPublishBot;
    return this._http.post<any>( url, data );
  }


  getBotServiceDetails( botId: string ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlGetBotServiceDetails.replace('_tenantBotId_', botId);
    return this._http.get<any>( url );
  }


  getPlatformSupportedChannels(){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlGetPlatformChannels;
    return this._http.get<any>( url );
  }


  saveNewDialogs( payload: any ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlSaveNewDialogs;
    return this._http.post<any>( url, payload );
  }


  saveOrgDetails( payload: any ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlSaveOrgDetails;
    return this._http.post<any>( url, payload );
  }


  getAllTenants(){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlGetAllTenants;
    return this._http.get<any>( url );
  }
  

  getAllBotsForTenant( tenantId: number ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlGetAllBotsForTenant.replace("_tenantId_", tenantId.toString());
    return this._http.get<any>( url );
  }
  

  getAllIntentsForLibrary( libId: number ){
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }

    let url = this.adminServerUrl + this._urlGetAllIntentsForLibrary.replace("_libId_", libId.toString());
    return this._http.get<any>( url );
  }

  //Edit/Update
  // ---------------------------------------------------------------------------------
  updateTenant( newTenant: TenantModel, tenantId: number ) {
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }
    console.log("in dataservice" + tenantId);
    let url = this.adminServerUrl + this._urlUpdateTenant.replace("_tenantId_", tenantId.toString());;
    //return this._http.post<any>( url, newTenant );
  }

  updateNlpApp( newApp: NlpAppModel, nlpId: number ) {
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }
    console.log("in dataservice" + nlpId);
    let url = this.adminServerUrl + this._urlUpdateNlp.replace("_tenantId_", nlpId.toString());;
    //return this._http.post<any>( url, newTenant );
  }

  updateTool( newTool: ToolModel, toolId: number ) {
    if( this._isDummyRoutingActivated ){
      return of(this.getDummyResponse());
    }
    console.log("in dataservice" + toolId);
    let url = this.adminServerUrl + this._urlUpdateExt.replace("_tenantId_", toolId.toString());;
    //return this._http.post<any>( url, newTenant );
  }
  // ---------------------------------------------------------------------------------

  // ---------------------------------------------------------------------------------
  //  Dummy Routing

  public activateDummyRouting(){
    this._isDummyRoutingActivated = true;
    console.log("*_*\nDumb-mode ACTIVATED!!\nWe will not be requesting the DB to save our data utill the next page reload!");
  }

  private getDummyResponse(): {[key:string]: any}{
    return {
      'message': '*_* -> This response contains dummy data!',
      'result': this._tenantDetailsService.getAllDummyDetails()
    }
  }

  // ---------------------------------------------------------------------------------

  authenticateuser( data: any ) {
    const httpOptions = new HttpHeaders(this.header);
    console.log("3222222",httpOptions)
    const url = this.adminServerUrl + this._urlAzureAadAuth;
    // alert(data.username + url);
    return this._http.post<any>( url, data,{headers : httpOptions} );
  }
  initiateSSO(){
    console.log('Initiating SSO ...');
    //return this._http.get('https://csd-auth-service-dev.azurewebsites.net/api/v1/authenticate/security/auth/sso');
    return this._http.get('https://corpcsdchatbotzuulgw-preprod.azurewebsites.net/api/1.0/login.do?Type=admin');
  }

  public fetchAuthInfo(sessionToken: string) {
    const url = environment.apiGatewayBaseUrl + this.apiV2 + this.urlLoginDetails;
    // const url = 'https://cdd-apigateway-02.azurewebsites.net/api/v2.0/' + 'login/details'
    const opt = {
      'headers': {
        'Content-Type': 'application/json',
        'csd-x-auth-token': sessionToken,
        'Access-Control-Allow-Origin' : '*'
      },
    };

    console.log('[fetchAuthInfo] Querying login at: ', url);
    console.log('[fetchAuthInfo] Options: ', opt);
    return this._http.get(url, opt);
  }

  public fetchAuthCredFromKeyvault() {
    let url = environment.apiGatewayBaseUrl + 'api/v2.0/admin/report/getAdminPortalCrendential'
    return this._http.get<any>( url );
  }

  constructor(
    private _http: HttpClient,
    private _tenantDetailsService: TenantDetailsService,
  ) { }
}
