import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})

export class FilterPipe implements PipeTransform{

    transform(items: any[], searchText: string, itemType: string, repoObj?: any): any[] {

        //console.log(items, searchText, repoObj);

        if(!items)
            return [];

        if(!searchText)
            return items;
        
        searchText = searchText.toLowerCase();
        return items.filter( it => {
            if( itemType === 'repoId' ){
                for( let idx in repoObj[it].intents ){
                    let intent = repoObj[it].intents[idx];

                    //console.log(`intentName: ${intent['intentName']}`);

                    if( intent['intentName'].toLowerCase().includes(searchText) )
                        return true;
                }
                return false;
            }else if( itemType === 'intentId' ){
                return it.intentName.toLowerCase().includes(searchText);
            }else{
                return true;
            }
        });
    }
}
