import { DecoratedText } from './../../models/DecoratedText';
import { GetChathistoryService } from '../../services/get-chathistory.service';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { ChatHistoryReport, ChatData } from "../../models/chat-history-report-model";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { Workbook } from "exceljs";
import { ToastService } from '../../services/toast.service';
import * as fs from "file-saver";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ChartType, ChartOptions,ChartDataSets } from 'chart.js';
import { SingleDataSet, Label, monkeyPatchChartJsLegend, monkeyPatchChartJsTooltip } from 'ng2-charts';
import { forkJoin } from 'rxjs';
import { ExcelService } from 'src/app/services/exel.service';
import domtoimage from 'dom-to-image';
import jspdf from 'jspdf';
import { NgxSpinnerService } from "ngx-spinner";
import { HttpErrorResponse } from '@angular/common/http';
import * as _moment from 'moment';
const moment = _moment;


pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-chathistory-report',
  templateUrl: './chathistory-report.component.html',
  styleUrls: ['./chathistory-report.component.css']
})
export class ChathistoryReportComponent implements OnInit , OnDestroy {

  public bardata=[];
  public bardata1=[];

  chatHistoryForm : FormGroup;
  chatHistoryResponse : any
  showChatHistoryFlag = false
  loading = true;
  startDate:any
  endDate:any
  index = 0
  header = [
    "Sr. No.",
    "Requester Name",
    "Requester ID",
    "Region",
    "Use Case Start Time",
    "Use Case End Time",
    "Average Speed To Answer",
    "Use Case Query",
    "Language",
    "Transfer To Live Agent",
    "Session Survey",
    "Did My Bot work for you as expected?",
    "Was it easy to use?",
    "Did My Bot add value to your needs today?",
    "What did you like or dislike about My Bot?",
    "What feature would you like to see in future release?",
    "Average Handling Time",
    "Chat Transcript"]
  report = new ChatHistoryReport();

  constructor( private formBuilder: FormBuilder,
    private getChathistoryService:GetChathistoryService,
    private _toastService: ToastService,
    private excelService:ExcelService,
    private SpinnerService: NgxSpinnerService) {
      this.report = JSON.parse(sessionStorage.getItem("report")) || new ChatHistoryReport();

    if (!this.report.chatData || this.report.chatData.length === 0) {
      this.report.chatData = [];
      this.report.chatData.push(new ChatData());
    }
     }


     p: Number = 1;
     q: Number =1;



     count: Number = 10;
     public data;
     onopenclick(transcript: any) {
       var modal = document.getElementById("myModal");
       this.data = transcript;
       modal.style.display = "block";
     }
     oncloseclick() {
       var modal = document.getElementById("myModal");
       modal.style.display = "none";
     }

      //bar-chart

      public pieChartColors: Array < any > = [{
        backgroundColor: ['#066070', '#a4d5de', '#2eb9d1','#396c75'],
        //borderColor: ['rgba(135,206,250,1)', 'rgba(106,90,205,1)', 'rgba(148,159,177,1)','rgba(164,159,177,1)','rgba(180,159,177,1)']
     }];

     public barChartColors: any [] =[
      {
          backgroundColor:'#696968',
          borderColor: "rgba(135,206,250,1)",
         //borderWidth: 5
      }

    ]

  public barChartOptions1: ChartOptions = {
    responsive: true,
  };
  public barChartLabels1: Label[] = [];
  public barChartType1: ChartType = 'bar';
  public barChartLegend1 = true;
  public barChartPlugins1 = [];

  public barChartData1: ChartDataSets[] = [
    { data: [], label: 'Counts' }
  ];


  public barChartOptions: ChartOptions = {
    responsive: true,
  };
  public barChartLabels: Label[] = ['Satisfied','Neutral','Dissatisfied'];
  public barChartType: ChartType = 'bar';
  public barChartLegend = true;
  public barChartPlugins = [];

  public barChartData: ChartDataSets[] = [
    { data: [100,60,10], label: 'Counts' }
  ];

  //piecharts

  public pieChartOptions: ChartOptions = {
    responsive: true,
  };

  public pieChartLabels1: Label[] = ['Total Chats closed within 1 min','Total Chats closed within 5 mins','Total Chats Completed','Answered'];
  public pieChartData1: SingleDataSet = [300,500,150,200,300];
  public pieChartType1: ChartType = 'pie';
  public pieChartLegend1 = true;
  public pieChartPlugins1 = [];

  public pieChartLabels2: Label[] = ['Chats Answered','Chats Unanswered','Total Incident','Total Service Request'];
  public pieChartData2: SingleDataSet = [300, 500, 150,100];
  public pieChartType2: ChartType = 'pie';
  public pieChartLegend2 = true;
  public pieChartPlugins2 = [];

  userDetails : any = [];
  chat :any =[];
  queueLevelReport : any = [];
  agentLevelReport : any = [];
  dateLevelReport : any = [];
  view: [number,number] = [1000, 300];
  gradient: boolean = true;
  showLegend: boolean = true;
  showLabels: boolean = true;
  isDoughnut: boolean = true;
  colorScheme :any = {
    domain: ['#9AAEF9', '#FF0096','#36417b','#FE7300', '#04115A', '#8fd6e5',]
  };
  exportAsXLSX():void {
    this.excelService.exportAsExcelFile(this.usersList, 'userListReport');
  }

  exportAsXLSX1():void {
    this.excelService.exportAsExcelFile(this.usersListWithDate, 'usersListWithDateReport');
  }
  exportAsXLSX2():void {
    this.excelService.exportAsExcelFile(this.countryWiseCount, 'countryWiseCountReport');
  }
  exportAsXLSX3():void {
    this.excelService.exportAsExcelFile(this.languageWiseCount, 'languageWiseCountReport');
  }
  exportAsXLSX4():void {
    this.excelService.exportAsExcelFile(this.useCaseWiseCount, 'useCaseWiseCountReport');
  }
  exportAsXLSX5():void {
    this.excelService.exportAsExcelFile(this.useCaseWiseFeedback, 'useCaseWiseFeedbackReport');
  }
  exportAsXLSX6():void {
    this.excelService.exportAsExcelFile(this.liveAgentFeedback, 'liveAgentChatFeedbackReport');
  }

  // exportAsXLSX7():void {
  //   this.excelService.exportAsExcelFile2(this. knowledgeArticleReport_ISS_SUCCESSKBS,
  //     this.knowledgeArticleReport_ISS_SKIPPEDKBS,
  //     this.knowledgeArticleReport_ISS_ALLKEYWORD,
  //     this.knowledgeArticleReport_ISS_SUCCESS,
  //     this.knowledgeArticleReport_ISS_FAILED,
  //     this.knowledgeArticleReport_ISS_NOTFOUND,
  //   'knowledgeArticleIssueReport');
  // }

  // exportAsXLSX8():void {
  //   this.excelService.exportAsExcelFile2(this. knowledgeArticleReport_REQ_SUCCESSKBS,
  //     this.knowledgeArticleReport_REQ_SKIPPEDKBS,
  //     this.knowledgeArticleReport_REQ_ALLKEYWORD,
  //     this.knowledgeArticleReport_REQ_SUCCESS,
  //     this.knowledgeArticleReport_REQ_FAILED,
  //     this.knowledgeArticleReport_REQ_NOTFOUND,
  //   'knowledgeArticleRequestReport');
  // }

  showModal : boolean;
  showModal1 : boolean;

  hide()
  {
    this.showModal = false;
  }
  showModel2(){
    console.log('hello')
    this.showModal1 = true;
    this.showModal = false;

  }
  hideModel2(){
    this.showModal1 =false;
  }

  backToModel1(){
    this.showModal1 = false;
    this.showModal = true;
  }

  exportingChatTranscript(): void{
    this.excelService.exportAsExcelFile(this.chat, 'Chat Transcript');
  }

  public Agent = [];
  public DateReport =[];
  public feedbacksurey=[];

  public feedbacksureybasedonagent=[];
  public getdetailedfeedbackReportBasisAverage=[]
  
  // User list reports
  usersList:any=[];
  usersList1:any=[];
  
  // User list reports with date
  usersListWithDate:any=[]; 
  usersListWithDate1:any=[]; 
  usersListWithDate2:any=[]; 
  
  //country reports
  countryWiseCount:any=[];
  countryWiseCount1:any=[];
  countryWiseCount2:any=[];
  
  //language reports
  languageWiseCount:any=[];
  languageWiseCount1:any=[];
  
  //usecase wise reports
  useCaseWiseCount:any=[];
  useCaseWiseCountAllMSTeams:any=[];
  useCaseWiseCountReportAnIssueMSTeams:any=[];
  useCaseWiseCountSubmitRequestMSTeams:any=[];
  useCaseWiseCountAllWebChat:any=[];
  // useCaseWiseCountKnowledgeArticleMSTeams:any=[];
  
  //knowledgearticlereports (Issue)
  // knowledgeArticleReport_ISS_SUCCESSKBS:any=[];
  // knowledgeArticleReport_ISS_SKIPPEDKBS:any=[];
  // knowledgeArticleReport_ISS_ALLKEYWORD:any=[];
  // knowledgeArticleReport_ISS_SUCCESS:any=[];
  // knowledgeArticleReport_ISS_FAILED:any=[];
  // knowledgeArticleReport_ISS_NOTFOUND:any=[];

  //knowledgearticlereports (Request)
  // knowledgeArticleReport_REQ_SUCCESSKBS:any=[];
  // knowledgeArticleReport_REQ_SKIPPEDKBS:any=[];
  // knowledgeArticleReport_REQ_ALLKEYWORD:any=[];
  // knowledgeArticleReport_REQ_SUCCESS:any=[];
  // knowledgeArticleReport_REQ_FAILED:any=[];
  // knowledgeArticleReport_REQ_NOTFOUND:any=[];
  
  // Usecase wise feedback reports
  useCaseWiseFeedback:any=[];
  useCaseWiseFeedback1:any=[];

  // Live agent feedback reports
  liveAgentFeedback:any=[];
  liveAgentChatMSTeamsFeedback:any=[];
  liveAgentCallMSTeamsFeedback:any=[];
  callBackMSTeamsFeedback:any=[];
  liveAgentChatWebChatFeedback:any=[];
  dialInNumbersWebChatFeedback:any=[];

  interval: any;
  ngOnInit(){
    let objectDate = new Date();
    let day :any= objectDate.getDate();
    day<10? day='0'+day:day
    let month:any = objectDate.getMonth()+1;
    month<10 ? month='0'+month:month
    let year = objectDate.getFullYear();

    let currentDate=year+'-'+month+'-'+day;
    let firstDate=year+'-'+month+'-'+'01';
    let maxDate=currentDate 
    this.chatHistoryForm = this.formBuilder.group({
      startdate: [firstDate, [
        Validators.required,
        // validates date format yyyy-mm-dd with regular expression
        Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)
    ]],
      enddate:  [currentDate, [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
    });
    this.showChatHistoryData(
     this.chatHistoryForm.value,true)
     this.interval = setInterval(() => {
      this.showChatHistoryData(
        this.chatHistoryForm.value,false)
    }, 1800000);
  }

  get f() {
    return this.chatHistoryForm.controls;
  }
  single:any = [
      {"name":"2023-07-03","value": 9},
        {"name":"2023-07-04","value": 21},
        {"name":"2023-07-05","value": 29},
        {"name":"2023-07-06","value": 31},
        {"name":"2023-07-07","value": 25},
  ]
  dateChanged(event:any){
    if((Math.abs(new Date(this.chatHistoryForm.value.enddate).getTime() - new Date(this.chatHistoryForm.value.startdate).getTime()))/ (1000 * 60 * 60 * 24)>31){
      const date :any=  new Date(this.chatHistoryForm.value.enddate) ;
      const newMonth=new Date(date.setMonth((date.getMonth())-1));
      const newDate= new Date(newMonth.setDate(date.getDate()));
      this.chatHistoryForm.value.startdate = moment(newDate).format('YYYY-MM-DD');
      this.chatHistoryForm.controls['startdate'].patchValue(moment(newDate).format('YYYY-MM-DD'))
     }
    }

  showChatHistoryData(chatHistoryFormData,flag){
    if(flag){
      this.showChatHistoryFlag=false;
      // this.SpinnerService.show();  
    }
    if (this.chatHistoryForm.invalid) {
      return;
    }
    this.startDate = chatHistoryFormData.startdate
    this.endDate = chatHistoryFormData.enddate
    forkJoin([
      this.getChathistoryService.getUsersList(chatHistoryFormData,false),
      this.getChathistoryService.getUsersList(chatHistoryFormData,true),
      
      this.getChathistoryService.getUsersListWithDate(chatHistoryFormData,'',false),
      this.getChathistoryService.getUsersListWithDate(chatHistoryFormData,'msteams',true),
      this.getChathistoryService.getUsersListWithDate(chatHistoryFormData,'webchat',true),
      
      this.getChathistoryService.getCountryWiseCount(chatHistoryFormData,'',false),
      this.getChathistoryService.getCountryWiseCount(chatHistoryFormData,'msteams',true),
      this.getChathistoryService.getCountryWiseCount(chatHistoryFormData,'webchat',true),
      
      this.getChathistoryService.getLanguageWiseCount(chatHistoryFormData,false),
      this.getChathistoryService.getLanguageWiseCount(chatHistoryFormData,true),
      
      this.getChathistoryService.getUseCaseWiseCount(chatHistoryFormData,"",""),
      this.getChathistoryService.getUseCaseWiseCount(chatHistoryFormData,"msteams","All"),
      this.getChathistoryService.getUseCaseWiseCount(chatHistoryFormData,"msteams","ReportAnIssue"),
      this.getChathistoryService.getUseCaseWiseCount(chatHistoryFormData,"msteams","SubmitRequest"),
      this.getChathistoryService.getUseCaseWiseCount(chatHistoryFormData,"webchat","All"),
      // this.getChathistoryService.getUseCaseWiseCount(chatHistoryFormData,"msteams","KnowledgeArticle"),
      
      this.getChathistoryService.getUseCaseWiseFeedback(chatHistoryFormData,''),
      this.getChathistoryService.getUseCaseWiseFeedback(chatHistoryFormData,'Chart'),
      
      this.getChathistoryService.getLiveAgentChatFeedback(chatHistoryFormData,'','',''),
      this.getChathistoryService.getLiveAgentChatFeedback(chatHistoryFormData,'LiveagentCall','msteams','Chart'),
      this.getChathistoryService.getLiveAgentChatFeedback(chatHistoryFormData,'LiveagentChat','msteams','Chart'),
      this.getChathistoryService.getLiveAgentChatFeedback(chatHistoryFormData,'CallBack','msteams','Chart'),
      this.getChathistoryService.getLiveAgentChatFeedback(chatHistoryFormData,'DialInNumbers','webchat','Chart'),
      this.getChathistoryService.getLiveAgentChatFeedback(chatHistoryFormData,'LiveagentChat','webchat','Chart'),

      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'ISS_SUCCESSKBS'),
      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'ISS_SKIPPEDKBS'),
      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'ISS_ALLKEYWORD'),
      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'ISS_SUCCESS'),
      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'ISS_FAILED'),
      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'ISS_NOTFOUND'),

      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'REQ_SUCCESSKBS'),
      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'REQ_SKIPPEDKBS'),
      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'REQ_ALLKEYWORD'),
      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'REQ_SUCCESS'),
      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'REQ_FAILED'),
      // this.getChathistoryService.getknowledgeArticleReports(chatHistoryFormData,'REQ_NOTFOUND'),

    ]).subscribe(data=>{
      if(flag){
        this.showChatHistoryFlag=true;
        // this.SpinnerService.hide();  
      }
      this.usersList=data[0].data;  
      this.usersList1=data[1] && data[1].data[0] && data[1].data[0].count;
      
      this.usersListWithDate=data[2].data;
      this.usersListWithDate1=data[3].data;
      this.usersListWithDate2=data[4].data;
      
      this.countryWiseCount=data[5].data;
      this.countryWiseCount1=data[6].data;
      this.countryWiseCount2=data[7].data;
      
      this.languageWiseCount=data[8].data
      this.languageWiseCount1=data[9].data
      
      this.useCaseWiseCount=data[10].data
      this.useCaseWiseCountAllMSTeams=data[11].data
      this.useCaseWiseCountReportAnIssueMSTeams=data[12].data
      this.useCaseWiseCountSubmitRequestMSTeams=data[13].data
      this.useCaseWiseCountAllWebChat=data[14].data
      // this.useCaseWiseCountKnowledgeArticleMSTeams=data[15].data
      
      this.useCaseWiseFeedback=data[15].data
      this.useCaseWiseFeedback1=data[16].data

      this.liveAgentFeedback=data[17].data
      this.liveAgentCallMSTeamsFeedback=data[18].data
      this.liveAgentChatMSTeamsFeedback=data[19].data
      this.callBackMSTeamsFeedback=data[20].data
      this.dialInNumbersWebChatFeedback=data[21].data
      this.liveAgentChatWebChatFeedback=data[22].data

      // this.knowledgeArticleReport_ISS_SUCCESSKBS=data[24].data
      // this.knowledgeArticleReport_ISS_SKIPPEDKBS=data[25].data
      // this.knowledgeArticleReport_ISS_ALLKEYWORD=data[26].data
      // this.knowledgeArticleReport_ISS_SUCCESS=data[27].data
      // this.knowledgeArticleReport_ISS_FAILED=data[28].data
      // this.knowledgeArticleReport_ISS_NOTFOUND=data[29].data

      // this.knowledgeArticleReport_REQ_SUCCESSKBS=data[30].data
      // this.knowledgeArticleReport_REQ_SKIPPEDKBS=data[31].data
      // this.knowledgeArticleReport_REQ_ALLKEYWORD=data[32].data
      // this.knowledgeArticleReport_REQ_SUCCESS=data[33].data
      // this.knowledgeArticleReport_REQ_FAILED=data[34].data
      // this.knowledgeArticleReport_REQ_NOTFOUND=data[35].data

     
    },
    (error:HttpErrorResponse)=>{
      // this.SpinnerService.hide();  
    })
  }

  generateExcell(){
    window.print();
  }

  generateExcel(){
    this.loading = false
    this.index = 0;
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet("Overall Report");
    let title =
      "Overall report created for duration " +
      this.startDate +
      "to " +
      this.endDate;
    let titleRow = worksheet.addRow([title]);

    let headerRow = worksheet.addRow(this.header);
    worksheet.autoFilter = {
      from: 'A1',
      to: 'P1',
    }
    headerRow.eachCell((cell, number) => {
      cell.fill = {
        type: "pattern",
        pattern: "solid",
        fgColor: { argb: "FFFFFF00" },
        bgColor: { argb: "FF0000FF" },
      };
      cell.border = {
        top: { style: "thin" },
        left: { style: "thin" },
        bottom: { style: "thin" },
        right: { style: "thin" },
      };
    });
    this.chatHistoryResponse.map((res) => {
      let arr = []
      let qaPair:any = ""
      res.map((ele)=>{
        if(ele.user_utterance != ""){
          qaPair = qaPair + "\r\n USER:" + ele.user_utterance
        }
        else if(ele.bot_response != ""){
          let bot_response = new DecoratedText(ele.bot_response);
          let text = "";
          bot_response.decoratedTextList.map((val) => {
            text = text + val.text;
          });
          qaPair = qaPair + "\r\n BOT:" + text
        }
      })
      this.index = this.index + 1
      if(res.length == 1 ){
        if(res[0].session_feedback){
          let row = worksheet.addRow([this.index, res[0].user_name, res[0].user_id,"", res[0].usecase_start_timestamp.substring(0,10)+" "+res[0].usecase_start_timestamp.substring(11,19), res[0].usecase_end_timestamp.substring(0,10)+" "+res[0].usecase_end_timestamp.substring(11,19),res[0].AverageGreetingTime, res[0].usecase_name,"","", res[0].session_feedback,res[0].que2,res[0].que3,res[0].que4,res[0].que5,res[0].que6, res[0].AverageHandlingTime,qaPair]);
        }
        else{
          let row = worksheet.addRow([this.index, res[0].user_name, res[0].user_id,"", res[0].usecase_start_timestamp.substring(0,10)+" "+res[0].usecase_start_timestamp.substring(11,19), res[0].usecase_end_timestamp.substring(0,10)+" "+res[0].usecase_end_timestamp.substring(11,19),res[0].AverageGreetingTime, res[0].usecase_name,"","", "No",res[0].que2,res[0].que3,res[0].que4,res[0].que5,res[0].que6, res[0].AverageHandlingTime,qaPair]);
        }

      }
      else{
        if(res[1].session_feedback){
          let row = worksheet.addRow([this.index, res[1].user_name, res[1].user_id,"", res[1].usecase_start_timestamp.substring(0,10)+" "+res[1].usecase_start_timestamp.substring(11,19), res[1].usecase_end_timestamp.substring(0,10)+" "+res[1].usecase_end_timestamp.substring(11,19),res[1].AverageGreetingTime, res[1].usecase_name,"","", res[1].session_feedback,res[1].que2,res[1].que3,res[1].que4,res[1].que5,res[1].que6, res[1].AverageHandlingTime,qaPair]);
        }
        else{
          let row = worksheet.addRow([this.index, res[1].user_name, res[1].user_id,"", res[1].usecase_start_timestamp.substring(0,10)+" "+res[1].usecase_start_timestamp.substring(11,19), res[1].usecase_end_timestamp.substring(0,10)+" "+res[1].usecase_end_timestamp.substring(11,19),res[1].AverageGreetingTime, res[1].usecase_name,"","", "No",res[1].que2,res[1].que3,res[1].que4,res[1].que5,res[1].que6, res[1].AverageHandlingTime,qaPair]);
        }

      }

    });

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], {
        type:
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      let fname =
        "overall_report_" + this.startDate + "_" + this.endDate + ".xlsx";
      fs.saveAs(blob, fname);
    });
    this.loading = true;
  }
  ngOnDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  
}
