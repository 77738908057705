import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { of } from 'rxjs';

import { AppStateService } from '../app-state.service';
import {BehaviorSubject, Observable} from 'rxjs';
import { environment } from 'src/environments/environment';


import { Http, RequestOptions, Headers, RequestMethod } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class SocketImpl {
  public chatCompleted: BehaviorSubject<any>;
  public conn:WebSocket;
  public keepAlive = {value:"true"}

  public connect(){
      this.conn =  new WebSocket("wss://lwausazeachatbotmetrics-dev.azurewebsites.net/api/v1/metrics");
      this.conn.onopen = () => {
          console.log('connected');
          this.conn.send("connected")
      };

      this.conn.onmessage = (data) => {
          this.setValue(JSON.parse(data.data))
          this.conn.send(JSON.stringify(this.keepAlive))
      }

  }

  getHeader(): HttpHeaders{
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
    headers.set('Access-Control-Allow-Origin', '*');
    return headers;
  }
  private header = {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*'
  };

  public closeConnection(){
      this.conn.close()
      console.log("closed")
  }
  getValue(): Observable<any> {
      return this.chatCompleted.asObservable();
    }
  public setValue(newValue): void {
      console.log("--->from server",newValue)
      this.chatCompleted.next(newValue);
    }

  public fetchFromMetricServer(date:any){
      let url = environment.metricServerUrl + "/api/1.0/metrics/report/u7/getCountByTime/"+ date
      let url1 = "https://corpcsdchatbotmetricservice-preprod.azurewebsites.net/api/v1/metrics/feedbackSurvey/getReportByTime/startDate/"+date+"/endDate/"+date;
      return this._http.get<any>(url1, {headers:this.getHeader()});
  }

  public getLiveAgentDetailsByTime(date:any){
    let url = environment.metricServerUrl + "/api/1.0/metrics/report/u7/getCountByTime/"+ date
    let url1 = "https://iff-chatbot-dev-platformservice.azurewebsites.net/api/v2.0/admin/iffReport/getLiveAgentDetailsByTime/"+date;
    return this._http.get<any>(url1, {headers:this.getHeader()});
}

public getLiveUserStatusByTime(date:any){
  let url = environment.metricServerUrl + "/api/1.0/metrics/report/u7/getCountByTime/"+ date
  let url1 = "https://iff-chatbot-dev-platformservice.azurewebsites.net/api/v2.0/admin/iffReport/getLiveUserStatusByTime/"+date;
  return this._http.get<any>(url1, {headers:this.getHeader()});
}

public getLiveAgentStatusByTime(){
 // let url = environment.metricServerUrl + "/api/1.0/metrics/report/u7/getCountByTime/";
  let url1 = environment.apiGatewayBaseUrl + "api/v2.0/admin/livechat/getAllActiveAgentsCounts";
  return this._http.get<any>(url1, {headers:this.getHeader()});
}

public getUserAgentStatusByTime(date:any){
  let url = environment.metricServerUrl + "/api/1.0/metrics/report/u7/getCountByTime/"+ date
  let url1 = "https://iff-chatbot-dev-platformservice.azurewebsites.net/api/v2.0/admin/iffReport/getUserAgentDetailsByTime/"+date;
  return this._http.get<any>(url1, {headers:this.getHeader()});
}

public getWaitQueueCount(){
  //let url = environment.metricServerUrl + "/api/1.0/metrics/report/u7/getCountByTime/"+ date
  let url1 = environment.apiGatewayBaseUrl + "api/v2.0/admin/livechat/getUserInQueueCountPerLanguage";
  return this._http.get<any>(url1, {headers:this.getHeader()});
}

public get_All_agent_by_details(){
  //let url = environment.metricServerUrl + "/api/1.0/metrics/report/u7/getCountByTime/"+ date
  let url1 = environment.apiGatewayBaseUrl + "api/v2.0/admin/livechat/getAgentDetails/all";
  return this._http.get<any>(url1, {headers:this.getHeader()});
}

public get_ongoing_chat_count(){
  //let url = environment.metricServerUrl + "/api/1.0/metrics/report/u7/getCountByTime/"+ date
  let url1 = environment.apiGatewayBaseUrl + "api/v2.0/admin/report/getActiveChatCountPerLanguage";
  return this._http.get<any>(url1, {headers:this.getHeader()});
}

public getReportBasisAverage(date:any){

  //let Url='https://asksambotplatformserviceuat.azurewebsites.net/api/v2.0/admin/report/getChatDetails/'+fromDate+'/'+toDate;
  let Url=environment.apiGatewayBaseUrl + 'api/v2.0/admin/report/getReportBasisAverage/'+date+'/'+date;
  return this._http.get<any>(Url);
}


constructor(private _http:HttpClient,private _appStateService: AppStateService) {
    this.chatCompleted = new BehaviorSubject<any>(this.chatCompleted);
 }


}
