import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, EMPTY } from 'rxjs';
import { map, catchError } from "rxjs/operators";
import { Router } from '@angular/router';
import { AppStateService } from './app-state.service';
import { ToastService } from './toast.service';

@Injectable({
  providedIn: 'root'
})
export class ResponseInterceptorService implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req)
      .pipe(
        map((evt: HttpEvent<any>) => {
          if (evt instanceof HttpResponse) {
            console.log(` ** HttpResponse: ${JSON.stringify(evt)}`);
          }
          return evt;
        }),

        catchError((err: HttpErrorResponse) => {
          console.log(` ** ResponseInterceptorService: ${JSON.stringify(err)}`);
          // Redirect if 401
          if (err.status === 401) {
            this._toastService.showToast(2, 'Unauthorized access!!');
            this._router.navigateByUrl('/unauthorized-access');
            // this._router.navigate(['/unauthorized-access'], { queryParams: { unauthUrl: encodeURI(req.url) } });
            // this._router.navigateByUrl('/unauthorized-access', { queryParams: { unauthUrl: encodeURI(req.url) } });

            // Cancel the further operation on the response.
            return EMPTY;
          }
          return throwError(err);
        })
      );
  }


  constructor(
    private _appStateService: AppStateService,
    private _toastService: ToastService,
    private _router: Router,
  ) { }
}
