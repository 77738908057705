import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ToastService {

  shouldShowToast = false;
  private _toastDisplayDuration = 3000;
  private previousMsg: string;

  listToastMessage: ToastMessage[] = [];


  showToast( type: number, message: string ){
    // For same message as the previous one (already being displayed), do not show toast.
    if(message === this.previousMsg && this.listToastMessage.length > 0){
      console.log('Same toast message ... Hence not showing!');
      return;
    }

    this.previousMsg = message;
    this.shouldShowToast = true;
    this.listToastMessage.push( new ToastMessage( type, message ) );

    setTimeout( t => {
      // pop from list
      this.listToastMessage.shift();

      if( this.listToastMessage.length == 0 ){
        this.shouldShowToast = false;
      }
    }, this._toastDisplayDuration );
  }


  closeToast( idx ){
    this.listToastMessage.splice(idx, 1);
    if( this.listToastMessage.length == 0 ){
      this.shouldShowToast = false;
    }
  }

  constructor() { }
}



class ToastMessage {
  public typeText: string;

  constructor(
    public type: number,
    public message: string,
  ){
    switch (type) {
      case 1:
        this.typeText = 'Success';
        break;

      case 2:
        this.typeText = 'Error';
        break;
      
      case 3:
        this.typeText = 'Info';
        break;

      default:
        this.type = 1;
        this.typeText = 'Success';
        break;
    }
  }
}