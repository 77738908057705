import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AppStateService {

  // FLAGS
  public isViewModeActive: boolean = false;
  public isEditModeActive: boolean = false;
  public isValidUser: boolean = false;

  // COUNTERS
  public tenantCount: number = 0;
  public activeTenantCount: number = 0;
  public partiallyOnboardedTenantCount: number = 0;

  // Global values
  public username: string = "Admin";
  public authToken: string;

  // Getters Setters
  public getToken(){
    console.log(' ** Getting token ...');
    // return  localStorage.getItem('token');
    return this.authToken;
  }

  public setToken(token: string){
    // if(token.trim() != ''){
      localStorage.setItem('token', token);
      this.authToken=token;
    // }
  }

  public logoutUser(){
    sessionStorage.removeItem('authToken');
    this.router.navigateByUrl('/logout');            
  }

  constructor(
    private router: Router,
  ) { }
}
