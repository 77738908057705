import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ProgressbarService {

  public shouldShowRunningPbar = false;
  private _currentUrl: string;

  showRunningPbar(){
    this.shouldShowRunningPbar = true;
  }

  hideRunningPbar(){
    this.shouldShowRunningPbar = false;
  }

  constructor(private router : Router) {
    router.events.subscribe( (val) => {
      //console.log(this.router.url);
      if( this._currentUrl != this.router.url ){
        this._currentUrl = this.router.url;
        this.hideRunningPbar();
      }
    } );
  }
}
