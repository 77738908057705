import { Component, OnInit } from '@angular/core';
import { ToastService } from '../services/toast.service';
import { toastAnimation } from '../utils/toast-animation';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.css'],
  animations: [toastAnimation],
})
export class ToastComponent implements OnInit {

  constructor(
    public _toastService: ToastService,
  ) { }

  ngOnInit() {
  }

}
