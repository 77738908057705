import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-maintenance',
  templateUrl: './app-maintenance.component.html',
  styleUrls: ['./app-maintenance.component.css']
})
export class AppMaintenanceComponent implements OnInit {

  constructor() { }

  public unauthUrl: string;
  public shouldShow401 = false;
  public pageHeading = 'Under Maintenance';
  public pageBody = 'This site is currently unavailable as part of maintenance activity from dd/mm/yyyy hh:mm:ss to dd/mm/yyyy hh:mm:ss';
  public redirectText = 'Home';
  public redirectLink = '/admin/home';


  private configure401() {
    this.shouldShow401 = true;
    this.pageHeading = 'UNAUTHORIZED ACCESS';
    // if (this.unauthUrl && this.unauthUrl !== 'null') {
    //   this.pageBody = `You do not have the required privileges to access the page at '${this.unauthUrl}'!`;
    // } else {
    //   this.pageBody = `You do not have the required privileges to access the page!`;
    // }
    this.pageBody = `You do not have the required privileges to access the page!`;
    this.redirectText = 'Login as another user';
    this.redirectLink = '/login';
  }

  ngOnInit() {
  }

}
