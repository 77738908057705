import { trigger, state, transition, animate, style, query, group } from "@angular/animations";

export const modalFormAnimation = trigger( 'modalFormAnimation', [

    transition( ':enter', group([
        query( '.modal-dialog-1', [
            style({
                backgroundColor: 'rgba(0,0,0,0)',
            }),
            animate( '0.3s 100ms ease' )
        ] ),

        query( '.modal-dialog-1 > .dialog', [
            style({
                transform: 'translateY(-80px)',
                opacity: '0.0',
            }),
            animate( '0.2s 100ms ease-out' )
        ] ),
    ]) ),

    transition( ':leave', group([
        query( '.modal-dialog-1', [
            animate( '0.3s ease', style({
                backgroundColor: 'rgba(0,0,0,0)',
            }) ),
        ] ),

        query( '.modal-dialog-1 > .dialog', [
            animate( '0.2s ease', style({
                transform: 'translateY(-80px)',
                opacity: '0.0',
            }) ),
        ] ),
    ]) ),
] );